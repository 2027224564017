import PropTypes from 'prop-types';
import React from 'react';
import Banner from '../banner/Banner';
import styles from './banner-centered.module.scss';

const BannerCentered = ({ text, isHome }) => {
	return (
		<Banner className={styles.banner} isHome={isHome}>
			<p className={styles.text}>{text}</p>
		</Banner>
	);
};

BannerCentered.propTypes = {
	text: PropTypes.string,
	isHome: PropTypes.bool
};
export default BannerCentered;
