import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactVisibilitySensor from 'react-visibility-sensor';

const VisibilitySensor = ({ once, children, ...props }) => {
	const [isActive, setIsActive] = useState(true);

	return (
		<ReactVisibilitySensor
			active={isActive}
			onChange={isVisible => once && isVisible && setIsActive(false)}
			{...props}
		>
			{({ isVisible }) => children({ isVisible })}
		</ReactVisibilitySensor>
	);
};

VisibilitySensor.propTypes = {
	once: PropTypes.bool,
	children: PropTypes.func.isRequired
};

VisibilitySensor.defaultProps = {
	once: false
};

export default VisibilitySensor;
