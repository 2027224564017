import React from 'react';
import PropTypes from 'prop-types';
import SectionTitle from '@components/section-title';
import SectionDescriptionHome from '@components/section-description-home';
import layoutStyles from '@components/layout/layout.module.scss';
import { classNames } from '@utils';
import styles from './we-are-home.module.scss';
import Container from '@components/container';

const WeAreHome = (props) => {
	const {
		className,
		animate,
		intro,
		column,
		home,
		h2Title,
		h3Title,
		h2Subtitle,
		subSection
	} = props;
	return (
		<section className={classNames(className, styles.section, layoutStyles.headerPad, intro && styles.isIntro)}>
			<div className={styles.container}>
				<div className={classNames(styles.parallax)}>
					<div className={classNames(styles.row, column && styles.column)}>
						<div
							className={classNames(
								styles.content,
								column && styles.contentColumn,
								home && styles.contentHome
							)}
						>
							<SectionDescriptionHome
								animate={animate}
								isMain={true}
								{...props}
								h2Title={h2Title}
								h3Title={h3Title}
								h2Subtitle={h2Subtitle}
							/>
						</div>
					</div>
				</div>
				{subSection && (
					<Container isHome className={styles.subSectionContainer}>
						<section className={styles.subSectionTitle}>
							<SectionTitle title={subSection.title} />
						</section>
						<p className={styles.subSectionText}>{subSection.description}</p>
					</Container>
				)}
			</div>
		</section>
	);
};

WeAreHome.propTypes = {
	className: PropTypes.string,
	figureClassName: PropTypes.string,
	animate: PropTypes.bool,
	intro: PropTypes.bool
};

WeAreHome.defaultProps = {
	className: null,
	figureClassName: null,
	animate: false,
	intro: false
};

export default WeAreHome;
