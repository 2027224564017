import PropTypes from 'prop-types';
import React from 'react';
import styles from './section-title.module.scss';
import { classNames } from '@utils';

const SectionTitle = ({ title, h1Title, h2Title, h3Title, className }) => (
	<React.Fragment>
		{h1Title && <h1 className={classNames(className, styles.title)}>{title}</h1>}
		{h2Title && <h2 className={classNames(className, styles.title)}>{title}</h2>}
		{h3Title && <h3 className={classNames(className, styles.title)}>{title}</h3>}
		{!(h1Title || h2Title || h3Title) && <p className={classNames(className, styles.title)}>{title}</p>}
	</React.Fragment>
);

SectionTitle.propTypes = {
	title: PropTypes.string.isRequired,
	h1Title: PropTypes.bool,
	h2Title: PropTypes.bool,
	h3Title: PropTypes.bool,
	classNames: PropTypes.string
};

SectionTitle.defaultProps = {
	h1Title: false,
	h2Title: false,
	h3Title: false
};

export default SectionTitle;
