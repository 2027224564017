import { cmsModule } from '@utils';

const howWeDoIt = {
	label: 'How we do It',
	name: 'how-we-do-it',
	widget: 'object',
	required: true,
	fields: [
		{
			label: 'Title',
			name: 'title',
			widget: 'string'
		},
		{
			label: 'Description',
			name: 'description',
			required: true,
			widget: 'string'
		},
		{
			name: 'img',
			label: 'Image',
			required: true,
			widget: 'image'
		}
	]
};

const intro = cmsModule('intro');
const titleWithChecklist = cmsModule('title-with-checklist');
const productFeatures = cmsModule('product-features');

export default [
	{
		label: 'Title',
		name: 'title',
		widget: 'string'
	},
	{ ...intro, fields: intro.fields.filter(field => field.name === 'title' || field.name === 'description') },
	{
		...titleWithChecklist,
		fields: titleWithChecklist.fields.filter(field => field.name !== 'button'),
		name: 'benefits'
	},
	{
		...intro,
		fields: intro.fields.filter(field => field.name !== 'sectionTitle' && field.name !== 'buttons'),
		name: 'second-intro',
		label: 'Second Intro'
	},
	{ ...cmsModule('product-features'), name: 'benefits-2' },
	howWeDoIt,
	{
		...howWeDoIt,
		fields: howWeDoIt.fields.filter(field => field.name !== 'title'),
		name: 'how-we-do-it-2',
		label: 'Plan'
	},
	{
		...productFeatures,
		fields: productFeatures.fields.filter(field => field.name === 'items')
	},
	{
		label: 'Case Studies',
		name: 'case-studies',
		widget: 'object',
		required: true,
		fields: [
			{
				label: 'Title',
				name: 'title',
				required: true,
				widget: 'string'
			},
			{
				label: 'Cases',
				name: 'cases',
				widget: 'object',
				fields: [
					{
						label: 'Case 1',
						name: 'case1',
						widget: 'relation',
						collection: 'cases',
						display_fields: ['title'],
						search_fields: ['title'],
						required: true,
						value_field: 'slug'
					},
					{
						label: 'Case 2',
						name: 'case2',
						widget: 'relation',
						collection: 'cases',
						display_fields: ['title'],
						search_fields: ['title'],
						required: true,
						value_field: 'slug'
					}
				]
			}
		]
	},
	cmsModule('tools')
];
