export default {
	label: 'Scale Your Team',
	name: 'scaleYourTeam',
	widget: 'object',
	fields: [
		{
			name: 'title',
			widget: 'string'
		},
		{
			name: 'text',
			widget: 'text'
		},
		{
			label: 'Button',
			name: 'button',
			widget: 'object',
			fields: [
				{
					name: 'title',
					widget: 'string'
				},
				{
					name: 'link',
					widget: 'string'
				}
			]
		},
		{
			label: 'Image',
			name: 'image',
			widget: 'image'
		},
		{
			label: 'Image Alt',
			name: 'imageAlt',
			widget: 'string'
		}
	]
};
