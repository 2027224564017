import React from 'react';
import PropTypes from 'prop-types';

import SectionTitleVertical from '../section-title-vertical';
import styles from './as-seen-on.module.scss';

const AsSeenOn = ({ title, logos }) => (
	<section className={styles.container}>
		{title && <SectionTitleVertical title={title} />}
		<div className={styles.logosContainer}>
			{logos.map(({ image, name }, index) => (
				<img key={index} data-src={image} alt={name} className={styles.logo + ' lazyload'} />
			))}
		</div>
	</section>
);

AsSeenOn.propTypes = {
	title: PropTypes.string,
	logos: PropTypes.arrayOf(
		PropTypes.shape({
			image: PropTypes.string,
			name: PropTypes.string
		})
	)
};

AsSeenOn.defaultProps = {
	title: null
};

export default AsSeenOn;
