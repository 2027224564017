import { cmsModule } from '@utils';

const intro = cmsModule('intro');
const titleWithChecklist = cmsModule('title-with-checklist');
const productFeatures = cmsModule('product-features');

export default [
	{
		label: 'Title',
		name: 'title',
		widget: 'string'
	},
	{ ...intro, fields: intro.fields.filter((field) => field.name === 'title' || field.name === 'description') },
	{
		label: 'Intro Section',
		name: 'intro-section',
		widget: 'object',
		required: true,
		fields: [
			{
				label: 'First Paragraph',
				name: 'first-paragraph',
				widget: 'string'
			},
			{
				label: 'Highlight Paragraph',
				name: 'highlight-paragraph',
				widget: 'string'
			},
			{
				label: 'Achievements',
				name: 'achievements',
				widget: 'list',
				fields: [
					{
						name: 'image',
						label: 'Image',
						required: true,
						widget: 'image'
					},
					{
						name: 'text',
						label: 'text',
						required: true,
						widget: 'string'
					}
				]
			}
		]
	},
	{
		...cmsModule('title-with-checklist'),
		fields: titleWithChecklist.fields.filter((field) => field.name !== 'description'),
		name: 'benefits'
	},
	{
		label: 'How we do It',
		name: 'how-we-do-it',
		widget: 'object',
		required: true,
		fields: [
			{
				label: 'Title',
				name: 'title',
				required: true,
				widget: 'string'
			},
			{
				label: 'Description',
				name: 'description',
				required: true,
				widget: 'text'
			},
			{
				name: 'img',
				label: 'Image',
				required: true,
				widget: 'image'
			}
		]
	},
	{
		...productFeatures,
		fields: productFeatures.fields.filter((field) => field.name === 'items')
	},
	{
		label: 'Case Studies',
		name: 'case-studies',
		widget: 'object',
		required: true,
		fields: [
			{
				label: 'Title',
				name: 'title',
				required: true,
				widget: 'string'
			},
			{
				label: 'Cases',
				name: 'cases',
				widget: 'object',
				fields: [
					{
						label: 'Case 1',
						name: 'case1',
						widget: 'relation',
						collection: 'cases',
						display_fields: ['title'],
						search_fields: ['title'],
						required: true,
						value_field: 'slug'
					},
					{
						label: 'Case 2',
						name: 'case2',
						widget: 'relation',
						collection: 'cases',
						display_fields: ['title'],
						search_fields: ['title'],
						required: true,
						value_field: 'slug'
					}
				]
			}
		]
	},
	cmsModule('tools')
];
