import React from 'react';
import PropTypes from 'prop-types';
import { TranslateDirection } from './constants';
import { classNames } from '@utils';
import VisibilitySensor from '@components/visibility-sensor';
import styles from './animated.module.scss';

const AnimatedComponent = (props) => {
	const { animate, move, children } = props;
	return animate ? (
		<VisibilitySensor once partialVisibility>
			{({ isVisible }) => (
				<div
					className={classNames(
						styles.content,
						isVisible && (move === TranslateDirection.X ? styles.moveX : styles.moveY)
					)}
				>
					{children}
				</div>
			)}
		</VisibilitySensor>
	) : (
		<>{children}</>
	);
};

AnimatedComponent.propTypes = {
	animate: PropTypes.bool,
	move: PropTypes.oneOf(Object.keys(TranslateDirection))
};

AnimatedComponent.defaultProps = {
	animate: false,
	move: TranslateDirection.X
};

export default AnimatedComponent;
