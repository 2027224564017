export default {
	label: 'Digital Product Development',
	name: 'digitalProductDevelopment',
	widget: 'object',
	fields: [
		{
			name: 'title',
			widget: 'string'
		},
		{
			name: 'text',
			widget: 'text'
		},
		{
			name: 'cards',
			widget: 'list',
			fields: [
				{
					name: 'title',
					widget: 'string'
				},
				{
					name: 'text',
					widget: 'text'
				},
				{
					name: 'images',
					widget: 'list',
					field: {
						name: 'image',
						widget: 'image'
					}
				},
				{
					name: 'links',
					widget: 'list',
					fields: [
						{
							name: 'title',
							widget: 'string',
							required: false
						},
						{
							name: 'url',
							widget: 'string'
						}
					]
				}
			]
		}
	]
};
