import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import SectionTitle from '@components/section-title';
import styles from './contact-us.module.scss';

class ContactUs extends React.Component {
    constructor() {
        super();
        this.timerId = null;
    }

    loadHubspotForm() {
        window.hbspt.forms.create({
            region: 'na1',
            portalId: '21189190',
            formId: 'f179d70c-3d92-42a3-88bd-195d2e081633',
            target: '#hubspotForm'
        });
    }

    componentDidMount() {
        if (window && window.hbspt) {
            this.loadHubspotForm();
        } else {
            this.timerId = setTimeout(() => {
                if (window && window.hbspt) {
                    this.loadHubspotForm();
                }
            }, 1000);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timerId);
    }

    render() {
        return (
            <>
                <Helmet
                    script={[{ type: 'text/javascript', src: '//js.hsforms.net/forms/embed/v2.js', async: true }]}
                />
                <section className={styles.section} style={{ marginBottom: this.props.bottom }}>
                    <div className={styles.container}>
                        {this.props.showHeading && (
                            <div className={styles.heading}>
                                <SectionTitle title="Want to discuss your project?" h3Title />
                                <div className={styles.subtitle}>
                                    We'd love to hear about your project, feel free to reach out to us.
                                </div>
                            </div>
                        )}
                        <div id="hubspotForm" />
                    </div>
                </section>
            </>
        );
    }
}

ContactUs.propTypes = {
    pathname: PropTypes.string.isRequired,
    showHeading: PropTypes.bool
};

ContactUs.defaultProps = {
    showHeading: true
};

export default ContactUs;