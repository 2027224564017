import Icon from '@components/icon';
import Link from '@components/link';
import { classNames } from '@utils';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './social-media-links.module.scss';

const SocialMediaLinks = ({ className, socialNetworks }) => (
	<div className={classNames(styles.links, className)}>
		{Object.entries(socialNetworks).map(([name, link]) => (
			<Link key={name} {...link}>
				<Icon name={name} />
			</Link>
		))}
	</div>
);

SocialMediaLinks.propTypes = {
	className: PropTypes.string,
	socialNetworks: PropTypes.object.isRequired
};

SocialMediaLinks.defaultProps = {
	className: null
};

export default SocialMediaLinks;
