import { cmsModule } from '@utils';

export default [
	...cmsModule('heading'),
	{
		name: 'content',
		label: 'content',
		widget: 'markdown'
	},
	...cmsModule('summary')
];
