import React from 'react';
import PropTypes from 'prop-types';
import SectionTitle from '@components/section-title';
import SectionDescription from '@components/section-description';
import layoutStyles from '@components/layout/layout.module.scss';
import { classNames } from '@utils';
import styles from './we-are.module.scss';
import Container from '@components/container';

const WeAre = (props) => {
	const {
		className,
		figureClassName,
		animate,
		intro,
		column,
		home,
		h2Title,
		h3Title,
		h2Subtitle,
		subSection
	} = props;
	return (
		<section className={classNames(className, styles.section, layoutStyles.headerPad, intro && styles.isIntro)}>
			<div className={styles.container}>
				<div className={classNames(styles.row, column && styles.column)}>
					<div
						className={classNames(
							styles.content,
							column && styles.contentColumn,
							home && styles.contentHome
						)}
					>
						<SectionDescription
							animate={animate}
							isMain={true}
							{...props}
							h2Title={h2Title}
							h3Title={h3Title}
							h2Subtitle={h2Subtitle}
						/>
					</div>
					<div
						className={classNames(
							figureClassName,
							!home && styles.media,
							column && styles.mediaColumn,
							home && styles.mediaHome
						)}
					>
					</div>
				</div>
				{subSection && (
					<Container className={styles.subSectionContainer}>
						<section className={styles.subSectionTitle}>
							<SectionTitle title={subSection.title} />
						</section>
						<p className={styles.subSectionText}>{subSection.description}</p>
					</Container>
				)}
			</div>
		</section>
	);
};

WeAre.propTypes = {
	className: PropTypes.string,
	figureClassName: PropTypes.string,
	animate: PropTypes.bool,
	intro: PropTypes.bool
};

WeAre.defaultProps = {
	className: null,
	figureClassName: null,
	animate: false,
	intro: false
};

export default WeAre;
