const sectionFields = (label, name) => {
	return {
		label,
		name,
		required: false,
		widget: 'object',
		fields: [
			{
				label: 'Section title',
				name: 'sectionTitle',
				required: false,
				widget: 'string'
			},
			{
				label: 'title',
				name: 'title',
				required: false,
				widget: 'string'
			},
			{
				label: 'description',
				name: 'description',
				required: false,
				widget: 'markdown'
			}
		]
	};
};

const portfolioFieldHint = (name) => `${name} to display on portfolio (only if the case is highlighted)`;

const portfolioFields = [
	{
		label: 'Tags',
		name: 'tags',
		hint: portfolioFieldHint('Tags'),
		required: false,
		widget: 'list',
		field: {
			label: 'Tag',
			name: 'tag',
			widget: 'string'
		}
	},
	{
		label: 'Portfolio title',
		name: 'titlePortfolio',
		hint: portfolioFieldHint('Title'),
		required: false,
		widget: 'markdown'
	},
	{
		label: 'Portfolio image',
		name: 'imagePortfolio',
		hint: portfolioFieldHint('Image'),
		required: false,
		widget: 'image'
	},
	{
		label: 'Portfolio mockup',
		name: 'mockupPortfolio',
		hint: portfolioFieldHint('Mockup'),
		required: false,
		widget: 'image'
	}
];

export default [
	{
		label: 'Title',
		name: 'title',
		widget: 'string'
	},
	{
		label: 'Image',
		name: 'image',
		required: false,
		widget: 'image'
	},
	{
		label: 'Color',
		name: 'color',
		widget: 'string'
	},
	sectionFields('About', 'about'),
	{
		label: 'Image section',
		name: 'image-section',
		required: false,
		widget: 'image'
	},
	{
		label: 'Image section Width 100%',
		name: 'image-section-width-100',
		widget: 'boolean',
		required: false
	},
	sectionFields('Challenge', 'challenge'),
	{
		label: 'Carousel section 1(Between Challenge and Solution)',
		name: 'carousel-section-1',
		required: false,
		widget: 'image'
	},
	sectionFields('Solution', 'solution'),
	{
		label: 'Carousel section 2(Between Solution and Results)',
		name: 'carousel-section-2',
		required: false,
		widget: 'image'
	},
	sectionFields('Results', 'results'),
	{
		label: 'Carousel section 3(After Results)',
		name: 'carousel-section-3',
		required: false,
		widget: 'image'
	},
	...portfolioFields
];
