import { cmsModule } from '@utils';

export default {
	label: 'Headquarters',
	name: 'l-a-headquarters',
	widget: 'object',
	fields: [
		...cmsModule('section-description'),
		{
			label: 'Image',
			name: 'image',
			widget: 'image'
		},
		{
			label: 'Image Alt',
			name: 'imageAlt',
			widget: 'string'
		}
	]
};
