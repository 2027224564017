import Container from '@components/container';
import Icon from '@components/icon';
import { Breakpoints, classNames } from '@utils';
import React from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/swiper.scss';
import styles from './swiper.module.scss';

class SwiperComponent extends React.Component {
	constructor() {
		super();

		this.swiperParams = {
			slidesPerView: 'auto',
			getSwiper: this.getSwiper.bind(this)
		};

		this.state = {
			swiper: null,
			isBeginning: true,
			isEnd: false
		};

		this.handleNext = this.handleNext.bind(this);
		this.handlePrev = this.handlePrev.bind(this);
	}

	getSwiper(swiper) {
		if (swiper) {
			this.setState({
				swiper,
				isBeginning: swiper.isBeginning,
				isEnd: swiper.isEnd
			});

			swiper.on('reachBeginning reachEnd fromEdge slideChange', () => {
				this.setState({
					isBeginning: swiper.isBeginning,
					isEnd: swiper.isEnd
				});
			});
		}
	}

	handleNext() {
		const { swiper } = this.state;
		if (!swiper) {
			return;
		}

		swiper.slideNext();
	}

	handlePrev() {
		const { swiper } = this.state;
		if (!swiper) {
			return;
		}

		swiper.slidePrev();
	}

	renderNavigation() {
		return (
			<div className={classNames(styles.navigation, this.props.navigationStyle)}>
				<button
					className={classNames(styles.navButton, styles.prev)}
					onClick={this.handlePrev}
					disabled={this.state.isBeginning}
				>
					<Icon name="arrow-right" />
				</button>
				<button
					className={classNames(styles.navButton, styles.next)}
					onClick={this.handleNext}
					disabled={this.state.isEnd}
				>
					<Icon name="arrow-right" />
				</button>
			</div>
		);
	}

	render() {
		return (
			<section className={styles.section}>
				<Container
					styles={classNames(
						this.props.noPaddingRight && styles.noPaddingRight,
						this.props.noPaddingLeft && styles.noPaddingLeft
					)}
				>
					<Breakpoints>
						{(breakpoint) => {
							const carousel = this.props.carousel.includes(breakpoint);
							return (
								<div className={classNames(styles.list, this.props.className)}>
									{carousel && (
										<div className={styles.swiper}>
											<Swiper {...this.swiperParams}>{this.props.renderItems}</Swiper>
											{this.renderNavigation()}
										</div>
									)}
									{!carousel && this.props.renderItems}
								</div>
							);
						}}
					</Breakpoints>
				</Container>
			</section>
		);
	}
}

SwiperComponent.propTypes = {};

export default SwiperComponent;
