import { cmsModule } from '@utils';
export default {
	label: 'Product features',
	name: 'product-features',
	widget: 'object',
	required: false,
	fields: [
		{
			label: 'Title',
			name: 'title',
			widget: 'string',
			required: false
		},
		{
			label: 'Subtitle',
			name: 'subtitle',
			required: false,
			widget: 'string'
		},
		{
			label: 'Subtitle Image',
			name: 'subtitleImage',
			required: false,
			widget: 'image'
		},
		{
			label: 'Subtitle 2',
			name: 'subtitle2',
			required: false,
			widget: 'string'
		},
		{
			label: 'Items',
			name: 'items',
			widget: 'list',
			required: false,
			fields: [
				{
					label: 'Title',
					name: 'title',
					required: false,
					widget: 'string'
				},
				{
					label: 'Description',
					name: 'description',
					required: false,
					widget: 'markdown'
				},
				{
					label: 'Icon',
					name: 'icon',
					required: false,
					widget: 'image'
				},
				{
					label: 'Alt Icon',
					name: 'altIcon',
					required: false,
					widget: 'string'
				},
				{
					label: 'Img',
					name: 'img',
					required: false,
					widget: 'image'
				},
				{
					label: 'Alt Img',
					name: 'altImg',
					required: false,
					widget: 'string'
				},
				{
					label: 'List',
					name: 'list',
					required: false,
					widget: 'object',
					fields: cmsModule('list')
				},
				{
					label: 'Call to Action',
					name: 'link',
					required: false,
					widget: 'object',
					fields: cmsModule('link')
				}
			]
		}
	]
};
