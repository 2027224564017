export default {
	label: 'Cases Carousel',
	name: 'casesCarousel',
	widget: 'object',
	fields: [
		{
			label: 'Show',
			name: 'show',
			widget: 'boolean'
		},
		{
			label: 'Title',
			name: 'title',
			widget: 'string'
		},
		{
			label: 'Cases',
			name: 'cases',
			widget: 'list',
			field: {
				name: 'Case',
				label: 'Case',
				widget: 'relation',
				collection: 'cases',
				display_fields: ['title'],
				search_fields: ['title'],
				required: true,
				value_field: 'slug'
			}
		}
	]
};
