import { Capabilities, cmsModule, Industries } from '@utils';

export default [
	...cmsModule('heading'),
	{
		label: 'Items',
		name: 'items',
		widget: 'list',
		types: [
			{
				label: 'Case',
				name: 'case',
				widget: 'object',
				fields: [
					{
						label: 'Case',
						name: 'case',
						widget: 'relation',
						collection: 'cases',
						display_fields: ['title'],
						search_fields: ['title'],
						required: true,
						value_field: 'slug'
					},
					{
						label: 'Title',
						name: 'title',
						required: true,
						widget: 'markdown'
					},
					{
						label: 'Description',
						name: 'description',
						hint: 'Only shown on hover or mobile',
						required: true,
						widget: 'markdown'
					},
					{
						label: 'Icon',
						name: 'image',
						required: true,
						widget: 'image'
					},

					{
						label: 'Capabilities',
						name: 'capabilities',
						hint: 'Only the first one will determine the bg image',
						required: true,
						multiple: true,
						min: 1,
						widget: 'select',
						options: Object.entries(Capabilities).map(([value, label]) => ({ label, value }))
					},
					{
						label: 'Industries',
						hint: 'Only the first one will determine the bg color',
						name: 'industries',
						required: true,
						multiple: true,
						min: 1,
						widget: 'select',
						options: Object.entries(Industries).map(([value, label]) => ({ label, value }))
					},
					{
						label: 'Highlight',
						name: 'highlight',
						required: false,
						widget: 'boolean'
					},
					{
						label: 'Highlight Image',
						name: 'highlightImage',
						required: false,
						widget: 'image'
					},
					{
						label: 'Mobile',
						name: 'mobile',
						required: false,
						widget: 'boolean'
					}
				]
			},
			{
				label: 'Quote',
				name: 'quote',
				widget: 'object',
				fields: [
					{
						label: 'Quote',
						name: 'quote',
						widget: 'relation',
						collection: 'quotes',
						display_fields: ['author'],
						search_fields: ['author'],
						required: true,
						value_field: 'slug'
					}
				]
			}
		]
	}
];
