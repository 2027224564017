import React from 'react';
import PropTypes from 'prop-types';
import styles from './summary.module.scss';
import { Content } from '@components';
import slugify from 'slugify';
import { ReactMarkdown } from '@components';

const Summary = (props) => {
	return (
		<Content>
			<div className={styles.summary}>
				<h2 className={styles.summaryTitle}>{props.title}</h2>
				<ul className={styles.list}>
					{props.items.map((item, i) => {
						return (
							<li key={i} className={styles.listItem}>
								<a href={`#${slugify(item.title)}`} className={styles.link}>
									{item.title}
								</a>
							</li>
						);
					})}
				</ul>
			</div>
			<div className={styles.content}>
				{props.items.map((item, i) => (
					<div key={i}>
						<div id={slugify(item.title)} className={styles.anchor}></div>
						<div className={styles.item}>
							<h2 className={styles.itemTitle}>{item.title}</h2>
							<ReactMarkdown children={item.content} />
						</div>
					</div>
				))}
			</div>
		</Content>
	);
};

Summary.propTypes = {
	title: PropTypes.string,
	items: PropTypes.arrayOf(PropTypes.object)
};

Summary.defaultProps = {
	title: '',
	items: []
};

export default Summary;
