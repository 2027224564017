import { cmsModule } from '@utils';

export default [
	{
		name: 'twitter',
		label: 'Twitter',
		widget: 'object',
		fields: cmsModule('link')
	},
	{
		name: 'instagram',
		label: 'Instagram',
		widget: 'object',
		fields: cmsModule('link')
	},
	{
		name: 'linkedin',
		label: 'LinkedIn',
		widget: 'object',
		fields: cmsModule('link')
	},
	{
		name: 'facebook',
		label: 'Facebook',
		widget: 'object',
		fields: cmsModule('link')
	},
	{
		name: 'github',
		label: 'GitHub',
		widget: 'object',
		fields: cmsModule('link')
	},
	{
		name: 'youtube',
		label: 'YouTube',
		widget: 'object',
		fields: cmsModule('link')
	},
	{
		name: 'clutch',
		label: 'Clucth',
		widget: 'object',
		fields: cmsModule('link')
	},
	{
		name: 'amplify',
		label: 'Amplify',
		widget: 'object',
		fields: cmsModule('link')
	}
];
