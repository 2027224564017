import React from 'react';
import PropTypes from 'prop-types';
import { ReactMarkdown } from '@components';
import { classNames } from '@utils';
import Container from '@components/container';
import styles from './heading.module.scss';
import layoutStyles from '@components/layout/layout.module.scss';

const Heading = (props) => {
	const { reverse, title, subtitle, h2Title, h2Subtitle, h3Subtitle } = props;

	const {expandTitle} = props;

	return (
		<section className={classNames(styles.heading, layoutStyles.headerPad)}>
			<Container styles={styles.containerFlex} className={classNames(reverse && styles.reverse)}>
				<div className={expandTitle ? styles.expandedTitleContainer : (title.includes('Machine') ? styles.titleContainer : '')}>
					{!!title && !h2Title && <h1 className={styles.title}>{title}</h1>}
					{!!title && h2Title && (
						<h2 className={styles.title}>
							<ReactMarkdown children={title} />
						</h2>
					)}
				</div>
				{!!subtitle && !h2Subtitle && !h3Subtitle && <p className={styles.subtitle}>{subtitle}</p>}
				{!!subtitle && h2Subtitle && <h2 className={styles.subtitle}>{subtitle}</h2>}
				{!!subtitle && h3Subtitle && <h3 className={styles.subtitle}>{subtitle}</h3>}
			</Container>
		</section>
	);
};

Heading.propTypes = {
	reverse: PropTypes.bool,
	title: PropTypes.string,
	subtitle: PropTypes.string,
};

Heading.defaultProps = {
	reverse: false,
};

export default Heading;
