import { getWindow } from './window';

let _scrollingElement;

export const scrollingElement = () => {
	const _window = getWindow();

	if ('scrollingElement' in _window.document) {
		_scrollingElement = _window.document.scrollingElement;
	} else if (!_scrollingElement) {
		const initial = _window.document.documentElement.scrollTop;
		_window.document.documentElement.scrollTop = initial + 1;

		const updated = _window.document.documentElement.scrollTop;
		_window.document.documentElement.scrollTop = initial;

		_scrollingElement = updated > initial ? _window.document.documentElement : _window.document.body;
	}

	return _scrollingElement;
};
