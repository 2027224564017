export default {
	label: 'Machine Learning Consulting',
	name: 'machineLearningConsulting',
	widget: 'object',
	fields: [
		{
			name: 'title',
			widget: 'string'
		},
		{
			name: 'text',
			widget: 'text'
		},
		{
			name: 'cards',
			widget: 'list',
			allow_add: false,
			fields: [
				{
					name: 'image',
					widget: 'image'
				},
				{
					name: 'text',
					widget: 'string'
				}
			]
		},
		{
			label: 'Button',
			name: 'button',
			widget: 'object',
			fields: [
				{
					name: 'title',
					widget: 'string'
				},
				{
					name: 'link',
					widget: 'string'
				}
			]
		}
	]
};
