import React from 'react';
import PropTypes from 'prop-types';

const activableComponent = WrappedComponent => {
	class ActivableWrapper extends React.Component {
		constructor(props) {
			super();

			this.state = {
				active: props.active
			};

			this.toggle = this.toggle.bind(this);
			this.activate = this.activate.bind(this);
			this.deactivate = this.deactivate.bind(this);
		}

		activate() {
			this.setState(
				{
					active: true
				},
				() => {
					this.props.onActivate();
				}
			);
		}

		deactivate() {
			this.setState(
				{
					active: false
				},
				() => {
					this.props.onDeactivate();
				}
			);
		}

		toggle() {
			const method = this.state.active ? 'deactivate' : 'activate';
			this[method]();
		}

		render() {
			return (
				<WrappedComponent
					{...this.props}
					active={this.state.active}
					activate={this.activate}
					deactivate={this.deactivate}
					toggle={this.toggle}
				/>
			);
		}
	}

	ActivableWrapper.propTypes = {
		active: PropTypes.bool,
		onActivate: PropTypes.func,
		onDeactivate: PropTypes.func
	};

	ActivableWrapper.defaultProps = {
		active: false,
		onActivate: () => {},
		onDeactivate: () => {}
	};

	return ActivableWrapper;
};

export default activableComponent;
