import React from 'react';
import PropTypes from 'prop-types';
import { ReactMarkdown } from '@components';

import styles from './collapsible-content.module.scss';

class CollapsibleContent extends React.Component {
	constructor() {
		super();

		this.state = {
			shownContents: []
		};
	}

	handleReadMore(contentIndex) {
		this.setState({
			shownContents: [...this.state.shownContents, contentIndex]
		});
	}

	render() {
		if (!this.props.content) {
			return null;
		}

		const contents = this.props.content.split('[+]');

		return (
			<div className={styles.content}>
				{contents.map((content, i) => {
					const firstContent = i === 0;
					const nextContent = i + 1;
					const lastContent = nextContent === contents.length;
					const isVisible = firstContent || this.state.shownContents.includes(i);

					return (
						<span key={i}>
							{isVisible && <ReactMarkdown children={content} />}
							{isVisible && !lastContent && !this.state.shownContents.includes(nextContent) && (
								<a
									className={styles.readMore}
									href="javascript: void 0;"
									onClick={this.handleReadMore.bind(this, nextContent)}
								>
									Read more
								</a>
							)}
						</span>
					);
				})}
			</div>
		);
	}
}

CollapsibleContent.propTypes = {
	content: PropTypes.string
};

export default CollapsibleContent;
