import React, { Fragment } from 'react';
import styles from './nav.module.scss';
import PropTypes from 'prop-types';
import Icon from '@components/icon';
import Link from '@components/link';
import { classNames } from '@utils';

const NavItem = props => {
	const { links } = props.item;
	const item = {
		...props.item,
		links: null,
		id: props.id
	};
	const hasSubItems = !!links && !!links.length;

	return (
		<li className={classNames(styles.item, props.active && styles.itemActive)}>
			{!!item.to && (
				<Link {...item} className={styles.link}>
					{item.title}
				</Link>
			)}
			{!item.to && (
				<span className={classNames(styles.link, styles.linkHasSub)} onClick={props.onToggleSubMenu}>
					{item.title}
				</span>
			)}
			{hasSubItems && (
				<React.Fragment>
					<span className={styles.chevron}>
						<Icon name="chevron" onClick={props.onToggleSubMenu} />
					</span>
					<ul
						className={classNames(
							styles.subMenu,
							props.headerActive && styles.headerActive,
							props.active && styles.subMenuActive
						)}
					>
						{links.map((subItem, j) => {
							return (
								<li key={j} className={styles.subItem}>
									{!!subItem.to && (
										<Fragment>
											<Link {...subItem} className={styles.subLink}>
												{subItem.title}
											</Link>
											{ subItem.links && subItem.links.map((item) =>
												<Link {...item} className={styles.subLinkExtra}>
													{item.title}
												</Link>
											)}
										</Fragment>
									)}
									{!subItem.to && <span className={styles.subLink}>{subItem.title}</span>}
								</li>
							);
						})}
					</ul>
				</React.Fragment>
			)}
		</li>
	);
};

NavItem.propTypes = {
	id: PropTypes.string
};

export default NavItem;
