import React from 'react';
import PropTypes from 'prop-types';
import styles from './brand.module.scss';
import Link from '@components/link';
import Icon from '@components/icon';
import { classNames } from '@utils';

const Brand = props => {
	return (
		<div className={classNames(styles.brand, props.className)}>
			<Link to="/" className={styles.link}>
				<Icon name="uruit" className={styles.icon} />
			</Link>
			{props.tagline && <div className={styles.tagline}>{props.tagline}</div>}
		</div>
	);
};

Brand.propTypes = {
	tagline: PropTypes.string
};

export default Brand;
