export default {
	label: 'Filter Tags',
	name: 'tags',
	widget: 'object',
	required: false,
	fields: [
		{
			label: 'Title',
			name: 'title',
			widget: 'string',
			required: false
		},
		{
			label: 'Section Title',
			name: 'sectionTitle',
			widget: 'string',
			required: false
		},
		{
			label: 'Filters',
			name: 'filters',
			widget: 'list',
			fields: [
				{
					label: 'Label',
					name: 'label',
					required: true,
					widget: 'string'
				},
				{
					label: 'Tags',
					name: 'tags',
					required: true,
					widget: 'object',
					fields: {
						label: 'Words',
						name: 'words',
						required: true,
						widget: 'list'
					}
				}
			]
		}
	]
};
