import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from '@utils';
import Phone from '@components/phone';
import City from '@components/city';
import styles from './office.module.scss';

const Office = ({ className, name, phone, address, image }) => {
	return (
		<address className={classNames(styles.office, className)}>
			<figure className={styles.illustration}>
				<img className={styles.image + ' lazyload'} data-src={image} alt={name} />
			</figure>
			<City city={name} />
			{phone && <Phone phone={phone} />}
			{address && <div className={styles.address}>{address}</div>}
		</address>
	);
};

Office.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	image: PropTypes.string.isRequired
};

export default Office;
