export default {
	label: 'SanDiego',
	name: 'l-a-san-diego',
	widget: 'object',
	fields: [
		{
			label: 'Title',
			name: 'title',
			widget: 'string'
		},
		{
			label: 'Description',
			name: 'description',
			widget: 'string'
		},
		{
			label: 'Image',
			name: 'image',
			widget: 'image'
		},
		{
			label: 'Image Alt',
			name: 'imageAlt',
			widget: 'string'
		}
	]
};
