export default {
	label: 'Offering Intro',
	name: 'offering-intro',
	widget: 'object',
	fields: [
		{
			label: 'Title',
			name: 'title',
			widget: 'string'
		},
		{
			label: 'Description',
			name: 'description',
			widget: 'string'
		}
	]
};
