import { cmsModule } from '@utils';
import offices from '@static/cms/settings/locations.json';

const item = [
	{
		label: 'Title',
		name: 'title'
	},
	{
		label: 'To',
		name: 'to',
		required: false
	}
];

export default [
	{
		label: 'Description',
		name: 'description'
	},
	{
		label: 'Phone',
		name: 'phone'
	},
	{
		label: 'Email',
		name: 'email'
	},
	{
		label: 'Location Highlight',
		name: 'locationHighlight',
		widget: 'select',
		options: offices.offices.map((o) => o.name)
	},
	{
		label: 'Button bellow highlight location',
		name: 'buttonBellowHighlightLocation',
		widget: 'object',
		fields: [
			{
				label: 'Title',
				name: 'title'
			},
			{
				label: 'Link',
				name: 'link'
			}
		]
	},
	{
		label: 'Button bellow locations',
		name: 'buttonBellowLocations',
		widget: 'object',
		fields: [
			{
				label: 'Title',
				name: 'title'
			},
			{
				label: 'Link',
				name: 'link'
			}
		]
	},
	{
		label: 'List',
		name: 'list',
		widget: 'list',
		fields: [
			...item,
			{
				label: 'Sublist',
				name: 'sublist',
				required: false,
				widget: 'list',
				fields: item
			}
		]
	},
	{ ...cmsModule('primary-address'), name: 'primaryAddress' }
];
