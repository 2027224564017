import { cmsModule } from '@utils';

const trajectory = cmsModule('trajectory');

export default {
	label: 'Product Design',
	name: 'product-design',
	widget: 'object',
	fields: [
		{
			label: 'Image',
			name: 'image',
			widget: 'image'
		},
		{
			label: 'Image Alt',
			name: 'imageAlt',
			widget: 'string'
		},
		{
			label: 'Title',
			name: 'title',
			required: false,
			widget: 'string'
		},
		{
			label: 'Description',
			name: 'description',
			required: false,
			widget: 'text'
		},
		{
			label: 'Social Media Title',
			name: 'socialMediaTitle',
			required: false,
			widget: 'text'
		},
		{
			label: 'Contact Button',
			name: 'contactButton',
			required: false,
			widget: 'object',
			fields: [
				{
					label: 'Title',
					name: 'title',
					required: true,
					widget: 'string'
				},
				{
					label: 'link',
					name: 'link',
					required: true,
					widget: 'string'
				},
				{
					label: 'Filled',
					name: 'filled',
					required: false,
					widget: 'boolean'
				}
			]
		},
		{
			...trajectory,
			fields: trajectory.fields.filter((field) => field.name === 'milestones')
		},
		{
			label: 'Trajectory Button',
			name: 'trajectoryButton',
			widget: 'object',
			fields: [
				{
					label: 'Title',
					name: 'title',
					required: true,
					widget: 'string'
				},
				{
					label: 'link',
					name: 'link',
					required: true,
					widget: 'string'
				},
				{
					label: 'Filled',
					name: 'filled',
					required: false,
					widget: 'boolean'
				}
			]
		}
	]
};
