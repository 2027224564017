export default {
label: 'Clients Highlight',
name: 'clients-highlighted',
widget: 'object',
fields: [
    {
        label: 'Title',
        name: 'title',
        widget: 'string'
    },
    {
        label: 'Items',
        name: 'items',
        widget: 'list',
        fields: [
            {
                name: 'image',
                label: 'Image',
                required: false,
                widget: 'image'
            },
            {
                name: 'name',
                label: 'Name',
                required: false,
                widget: 'text'
            },
            {
                name: 'link',
                label: 'Link',
                required: false,
                widget: 'text'
            }
        ]
    }
]
};
