import React from 'react';
import PropTypes from 'prop-types';

const iconResolver = name => !!name && require(`@icons/${name}.svg`);

const Icon = props => {
	const Icon = iconResolver(props.name);

	return !!Icon && <Icon {...props} />;
};

Icon.propTypes = {
	name: PropTypes.string
};

export default Icon;
