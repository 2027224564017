import Link from '@components/link';
import PropTypes from 'prop-types';
import React from 'react';

const FooterListItem = ({ title, to, ...props }) =>
	to ? (
		<Link {...props} to={to}>
			{title}
		</Link>
	) : (
		<div {...props}>{title}</div>
	);

FooterListItem.propTypes = {
	title: PropTypes.string,
	to: PropTypes.string
};

FooterListItem.defaultProps = {
	title: null,
	to: null
};

export default FooterListItem;
