import React from 'react';

const getModernizr = () => {
	return (this || global || globalThis).window ? require('modernizr') : {};
};

const Modernizr = getModernizr();

const withModernizr = ModernizrComponent => {
	return class ModernizrWrapper extends React.Component {
		constructor() {
			super();
			this.state = {
				Modernizr: getModernizr()
			};
		}

		componentDidMount() {
			const Modernizr = getModernizr();

			this.setState({
				Modernizr
			});
		}

		render() {
			return <ModernizrComponent {...this.props} Modernizr={this.state.Modernizr} />;
		}
	};
};

export { withModernizr, Modernizr };
