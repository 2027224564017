import { cmsModule } from '@utils';

export default [
	{
		label: 'Offices',
		name: 'offices',
		widget: 'list',
		fields: cmsModule('office')
	}
];
