import React, { useState } from 'react';

import Link from '@components/link';
import styles from './cookies-banner.module.scss';
import Picture from '@components/picture';

const CookiesBanner = () => {
	const [showBanner, setShowBanner] = useState(true);

	const clickHandler = () => {
		typeof window !== 'undefined' &&
			window.localStorage.setItem('uruit-hideBanner', true) &&
			window.clarity('consent');
		setShowBanner(false);
	};

	React.useEffect(() => {
		const show = localStorage.getItem('uruit-hideBanner');
		if (show) {
			setShowBanner(false);
		}
	}, []);

	return (
		showBanner && (
			<div className={styles.container}>
				<Picture sources="/cms/assets/cookies.svg" alt="cookies-icon" />
				<p>
					This website uses cookies so that we can provide you with the best user experience. To read more
					about the cookies we use and to change your settings see our{' '}
					<Link className={styles.link} to="/privacy-policy" target="_blank">
						Cookies Policy
					</Link>
					.
				</p>
				<div onClick={clickHandler} className={styles.button}>
					OK, I understand
				</div>
			</div>
		)
	);
};

export default CookiesBanner;
