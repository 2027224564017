export default {
	label: 'Blog posts Carousel',
	name: 'blog-posts',
	widget: 'object',
	fields: [
		{
			label: 'Show',
			name: 'show',
			widget: 'boolean'
		},
		{
			label: 'Title',
			name: 'title',
			widget: 'string'
		},
		{
			label: 'Posts',
			name: 'posts',
			widget: 'list',
			fields: [
				{
					label: 'Title',
					name: 'title',
					widget: 'string'
				},
				{
					label: 'Image',
					name: 'image',
					widget: 'image'
				},
				{
					label: 'Category',
					name: 'category',
					widget: 'string'
				},
				{
					label: 'Description',
					name: 'description',
					widget: 'text',
					pattern: ['^.{1,200}$', 'Max length 200 characters']
				},

				{
					label: 'Info',
					name: 'info',
					widget: 'object',
					fields: [
						{
							label: 'Author Name',
							name: 'authorName',
							widget: 'string'
						},
						{
							label: 'Author Image',
							name: 'authorImg',
							widget: 'image'
						},
						{
							label: 'Date',
							name: 'date',
							widget: 'datetime',
							date_format: 'MMMM D, YYYY',
							time_format: false
						}
					]
				},

				{
					label: 'Link',
					name: 'link',
					widget: 'string'
				}
			]
		}
	]
};
