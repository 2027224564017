export default [
	{
		name: 'title',
		label: 'Title',
		widget: 'text'
	},
	{
		name: 'subtitle',
		label: 'subtitle',
		widget: 'text'
	},
	{
		name: 'description',
		label: 'description',
		widget: 'text'
	}
];
