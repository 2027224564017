export default {
	label: 'How We Do It',
	name: 'howWeDoIt',
	widget: 'object',
	fields: [
		{
			label: 'Section title',
			name: 'sectionTitle'
		},
		{
			label: 'Image',
			name: 'image',
			widget: 'image'
		},
		{
			label: 'Image Alt',
			name: 'imageAlt',
			widget: 'string'
		},
		{
			label: 'Steps',
			name: 'steps',
			widget: 'list',
			fields: [
				{
					label: 'Title',
					name: 'title',
					widget: 'string'
				},
				{
					label: 'Description',
					name: 'description',
					widget: 'text'
				}
			]
		},
		{
			label: 'Description',
			name: 'description'
		},
		{
			label: 'Button',
			name: 'button',
			widget: 'object',
			fields: [
				{
					name: 'title',
					widget: 'string'
				},
				{
					name: 'link',
					widget: 'string'
				}
			]
		}
	]
};
