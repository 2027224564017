import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from '@utils';
import GatsbyImageCore from './GatsbyImageCore';
import styles from './picture.module.scss';

const Picture = ({ sources, alt, className, imgClassName }) => {
	const isSvg = sources.match(/svg.*/i);

	return (
		<picture className={classNames(styles.picture, className)}>
			{isSvg ? (
				<img className={imgClassName + ' lazyload'} data-src={sources} alt={alt} />
			) : (
				<GatsbyImageCore imgClassName={imgClassName} sources={sources} alt={alt} />
			)}
		</picture>
	);
};

Picture.propTypes = {
	sources: PropTypes.string.isRequired,
	alt: PropTypes.string,
	className: PropTypes.string,
	imgClassName: PropTypes.string
};

Picture.defaultProps = {
	alt: '',
	className: '',
	imgClassName: ''
};

export default Picture;
