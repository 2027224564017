import { cmsModule } from '@utils';

export default [
	{
		name: 'title',
		label: 'Title',
		widget: 'string'
	},
	{
		name: 'subtitle',
		label: 'Subtitle',
		widget: 'string'
	},
	{
		name: 'image',
		label: 'Image',
		widget: 'image'
	},
	{
		name: 'links',
		label: 'Links',
		widget: 'object',
		fields: [
			{
				name: 'title',
				label: 'Title',
				widget: 'string'
			},
			{
				name: 'list',
				label: 'List',
				widget: 'list',
				fields: cmsModule('link')
			}
		]
	}
];
