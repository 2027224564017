export default [
	{
		name: 'summary',
		label: 'Summary',
		widget: 'list',
		fields: [
			{
				name: 'title',
				label: 'Title'
			},
			{
				name: 'content',
				label: 'Content',
				widget: 'markdown'
			}
		]
	}
];
