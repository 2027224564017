export default {
	label: 'Product features Grid',
	name: 'product-features-grid',
	widget: 'object',
	required: false,
	fields: [
		{
			label: 'Items',
			name: 'items',
			widget: 'list',
			fields: [
				{
					label: 'Title',
					name: 'title',
					required: false,
					widget: 'string'
				},
				{
					label: 'Description',
					name: 'description',
					required: false,
					widget: 'markdown'
				}
			]
		}
	]
};
