export default [
	{
		label: 'Name',
		name: 'name'
	},
	{
		label: 'Phone',
		name: 'phone'
	},
	{
		label: 'Address',
		name: 'address'
	},
	{
		label: 'Image',
		name: 'image',
		widget: 'image'
	}
];
