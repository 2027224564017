import Brand from '@components/brand';
import Button from '@components/button';
import Link from '@components/link';
import Locations from '@components/locations';
import Office from '@components/office';
import Phone from '@components/phone';
import socialNetworks from '@static/cms/settings/social-media.json';
import SocialMediaLinks from '@components/social-media-links';
import footerSettings from '@static/cms/settings/footer.json';
import locationsSettings from '@static/cms/settings/locations.json';
import { classNames } from '@utils';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './footer.module.scss';
import FooterList from './footer-list';
import Legal from './legal';

const Footer = ({ hideLocations, theme }) => {
	const { buttonBellowHighlightLocation, buttonBellowLocations, description, email, locationHighlight, phone } =
		footerSettings;
	const officeHighlight = locationsSettings.offices.find((o) => o.name === locationHighlight);

	return (
		<footer className={classNames(styles.footer, theme && theme.container)}>
			{!hideLocations && (
				<div className={styles.locationsContainer}>
					<div className={styles.highlight}>
						<div className={styles.info}>
							<p className={styles.description}>{description}</p>
							<div>
								<Phone className={styles.phone} phone={phone} />
								<Link className={styles.email} to={`mailto:${email}`}>
									{email}
								</Link>
							</div>
						</div>
					</div>
					<div className={styles.locationHighlight}>
						<div className={styles.officeContainer}>
							<Office className={styles.office} {...officeHighlight} />
						</div>
						<Button filled className={styles.buttonHighlight} {...buttonBellowHighlightLocation}>
							{buttonBellowHighlightLocation.title}
						</Button>
					</div>
					<div className={styles.locations}>
						<Locations excludeByName={locationHighlight} />
						<div className={styles.buttonContainer}>
							<Button filled className={styles.button} {...buttonBellowLocations}>
								{buttonBellowLocations.title}
							</Button>
						</div>
					</div>
				</div>
			)}
			<div className={styles.container}>
				<div className={styles.main}>
					<FooterList className={styles.footerList} items={footerSettings.list} />
					<SocialMediaLinks className={styles.socialMediaLinks} socialNetworks={socialNetworks} />
					<Brand className={styles.brand} />
				</div>
				<Legal />
			</div>
		</footer>
	);
};

Footer.propTypes = {
	hideLocations: PropTypes.bool
};

Footer.defaultProps = {
	hideLocations: false
};

export default Footer;
