import React from 'react';
import styles from './section.module.scss';
import { classNames } from '@utils';

const Section = (props) => {
	return (
		<main className={classNames(props.className, styles.section)}>
			<section className={styles.content}>{props.children}</section>
		</main>
	);
};

Section.propTypes = {};

export default Section;
