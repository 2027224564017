import { classNames } from '@utils';
import PropTypes from 'prop-types';
import React from 'react';
import FooterListItem from './footer-list-item';
import styles from './footer-list.module.scss';

const FooterList = ({ items, className }) => (
	<div className={classNames(styles.container, className)}>
		{items.map((item, i) => (
			<div key={i} className={styles.list}>
				<FooterListItem className={classNames(styles.title, item.to && styles.link)} {...item} />
				{item.sublist &&
					item.sublist.map((subitem, j) => (
						<FooterListItem key={j} className={subitem.to && styles.link} {...subitem} />
					))}
			</div>
		))}
	</div>
);

FooterList.propTypes = {
	items: PropTypes.array.isRequired,
	className: PropTypes.string
};

FooterList.defaultProps = {
	className: null
};

export default FooterList;
