import { cmsModule } from '@utils';

const descriptionField = cmsModule('section-description').find(field => field.name === 'description');

export default {
	name: 'intro',
	label: 'Intro',
	widget: 'object',
	fields: [
		...cmsModule('section-description').filter(field => field.name !== 'subtitle'),
		{
			...descriptionField,
			name: 'description-2',
			label: 'Description 2'
		}
	]
};
