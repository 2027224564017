import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { classNames } from '@utils';
import styles from './tag.module.scss';

const Tag = ({ word, className, onSelectTag, isClickeable }) => {
	const { key: wordKey, value: wordValue } = word;
	const [isSelected, setIsSelected] = useState(false);

	const handleClick = useCallback(() => {
		if (isClickeable) {
			setIsSelected(!isSelected);
			onSelectTag(wordKey);
		}
	}, [word, isSelected, isClickeable, onSelectTag]);

	return (
		<div
			onClick={handleClick}
			className={classNames(
				className ? className : styles.tag,
				isSelected && styles.isSelected,
				isClickeable && styles.isClickeable
			)}
		>
			{wordValue}
		</div>
	);
};

Tag.propTypes = {
	className: PropTypes.string,
	word: PropTypes.shape({ key: PropTypes.string, value: PropTypes.string }).isRequired,
	onSelectTag: PropTypes.func.isRequired,
	isClickeable: PropTypes.bool
};

Tag.defaultProps = {
	isClickeable: false
};

export default Tag;
