import { cmsModule } from '@utils';

export default [
	...cmsModule('heading'),
	cmsModule('intro'),
	{
		label: 'Intro list',
		name: 'intro-list',
		widget: 'object',
		required: false,
		fields: [
			{
				label: 'Title',
				name: 'title',
				widget: 'string',
				required: false
			},
			{
				label: 'List',
				name: 'list',
				required: false,
				widget: 'object',
				fields: cmsModule('list')
			}
		]
	},
	{ ...cmsModule('product-features'), name: 'benefits' },
	{
		label: 'How We Do It',
		name: 'how-we-do-it',
		widget: 'object',
		required: false,
		fields: [
			{
				label: 'Top Image',
				name: 'topImg',
				widget: 'image',
				required: false
			},
			{ ...cmsModule('product-features'), name: 'how-we-do-it-body' }
		]
	},
	{ ...cmsModule('product-features'), name: 'case-studies' },
	cmsModule('tools'),
	{ ...cmsModule('capabilities'), name: 'other-capabilities' }
];
