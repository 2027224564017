import SwiperComponent from '@components/swiper';
import React from 'react';
import BlogPost from './blog-post';
import styles from './blog-posts.module.scss';

const BlogPosts = ({ posts, title }) => {
	const carousel = ['lg', 'xl'];
	return (
		<section className={styles.section}>
			<div className={styles.title}>{title}</div>
			<SwiperComponent
				renderItems={posts.map((item, i) => {
					return (
						<div key={i} className={styles.item}>
							<BlogPost {...item} />
						</div>
					);
				})}
				carousel={carousel}
				navigationStyle={styles.navigation}
				noPaddingRight
				noPaddingLeft
			/>
		</section>
	);
};

export default BlogPosts;
