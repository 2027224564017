import { cmsModule } from '@utils';

export default {
	label: 'Reviews',
	name: 'carousel-quote-new',
	widget: 'object',
	required: false,
	fields: [
		{
			label: 'Title',
			name: 'title',
			widget: 'string'
		},
		{
			label: 'Items',
			name: 'items',
			widget: 'list',
			fields: [
				{
					name: 'id',
					widget: 'string'
				},
				...cmsModule('quote').map(field => {
					field.required = true;
					return field;
				})
			]
		}
	]
};
