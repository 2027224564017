import React from 'react';
import PropTypes from 'prop-types';
import styles from './hamburger.module.scss';
import { classNames } from '@utils';

const Hamburger = props => {
	return (
		<button
			onClick={props.onClick}
			title={props.title}
			className={classNames(styles.hamburger, props.active && styles.hamburgerActive, props.className)}
		>
			<span className={styles.title}>{props.title}</span>
		</button>
	);
};

Hamburger.propTypes = {
	active: PropTypes.bool,
	title: PropTypes.string,
	onClick: PropTypes.func
};

Hamburger.defaultProps = {
	active: false,
	title: 'Open menu',
	onClick: () => {}
};

export default Hamburger;
