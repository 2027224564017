import { cmsModule } from '@utils';

export default [
	...cmsModule('heading'),
	cmsModule('intro'),
	{
		label: 'Sections',
		name: 'sections',
		widget: 'list',
		fields: [
			{
				label: 'Title',
				name: 'title',
				required: true,
				widget: 'string'
			},
			{
				label: 'Subtitle',
				name: 'subtitle',
				required: true,
				widget: 'text'
			},
			{
				label: 'Image',
				name: 'image',
				required: false,
				widget: 'image'
			},
			{
				label: 'Questions',
				name: 'questions',
				widget: 'list',
				field: {
					label: 'Question',
					name: 'question',
					required: true,
					widget: 'string'
				}
			}
		]
	}
];
