import React from 'react';
import styles from './banner.module.scss';
import { classNames } from '@utils';

const Banner = props => {
	const { isLarge, className, smallContainer, isHome } = props;
	return (
		<div className={classNames(className, styles.banner, isLarge && styles.bannerLarge)}>
			<div className={classNames(smallContainer ? styles.containerSmall : styles.container, isHome ? styles.containerHome : styles.container)}>{props.children}</div>
		</div>
	);
};

export default Banner;
