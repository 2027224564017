import { cmsModule } from '@utils';

const casesPortfolioView = cmsModule('cases-portfolio-view');

export default [
	cmsModule('we-are-home'),
	cmsModule('clients'),
	cmsModule('services'),
	cmsModule('clients-highlighted'),
	{ ...cmsModule('banner-centered'), name: 'banner1' },
	cmsModule('how-we-do-it'),
	{ ...cmsModule('carousel-quote-new'), name: 'reviews' },
	cmsModule('as-seen-on'),
	{
		...casesPortfolioView,
		fields: casesPortfolioView.fields.filter((field) => field.name === 'title' || field.name === 'cases'),
		label: 'Success Stories',
		name: 'success-stories'
	}
];
