import React from 'react';
import PropTypes from 'prop-types';
import styles from './container.module.scss';
import { classNames } from '@utils';

const Container = props => {
	const className = classNames(
		props.isHome ? styles.containerHome : styles.container,
		props.small ? styles.containerSmall : styles.container,
		props.styles,
		props.className);
	return <div className={className}>{props.children}</div>;
};

Container.propTypes = {
	small: PropTypes.bool
};

Container.defaultProps = {
	small: false
};

export default Container;
