import { cmsModule } from '@utils';

const weAre = cmsModule('we-are');

export default [
	{
		...weAre,
		label: 'Heading',
		fields: weAre.fields.filter(field => {
			return field.name !== 'sectionTitle';
		})
	},
	cmsModule('intro'),
	cmsModule('product-features'),
	cmsModule('carousel-quote'),
	cmsModule('cases-carousel'),
	cmsModule('blog-posts')
];
