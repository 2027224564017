import { cmsModule } from '@utils';

export default {
	label: 'Cases',
	name: 'cases-portfolio-view',
	widget: 'object',
	fields: [
		...cmsModule('section-description'),
		{
			label: 'Cases',
			name: 'cases',
			widget: 'object',
			fields: [
				{
					label: 'Case 1',
					name: 'case1',
					widget: 'relation',
					collection: 'cases',
					display_fields: ['title'],
					search_fields: ['title'],
					required: true,
					value_field: 'slug'
				},
				{
					label: 'Case 2',
					name: 'case2',
					widget: 'relation',
					collection: 'cases',
					display_fields: ['title'],
					search_fields: ['title'],
					required: true,
					value_field: 'slug'
				}
			]
		}
	]
};
