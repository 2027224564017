import React from 'react';
import PropTypes from 'prop-types';
import styles from './nav.module.scss';
import Hamburger from '@components/hamburger';
import Button from '@components/button';
import { Breakpoints, classNames } from '@utils';
import NavItem from './NavItem';
import activable from './activable';

const Nav = activable(
	class Nav extends React.Component {
		constructor() {
			super();

			this.state = {
				activeSubMenu: null
			};
		}

		toggleSubMenu(i) {
			const isDeactivating = this.state.activeSubMenu === i;
			this.setState(
				{
					activeSubMenu: isDeactivating ? null : i
				},
				() => {
					this.props[isDeactivating ? 'onDeactivateSubMenu' : 'onActivateSubMenu']();
				}
			);
		}

		render() {
			const { headerActive, active, white, toggle, items } = this.props;

			const className = classNames(
				this.props.className,
				styles.nav,
				white && styles.navWhite,
				active && styles.navActive,
				headerActive && styles.navHeaderActive
			);
			return (
				<div className={className}>
					<Hamburger onClick={toggle} className={styles.hamburger} active={active} />
					<nav className={styles.navigation}>
						{items.length && (
							<ul className={styles.list}>
							{items.map((item, i) =>
								item.showAsButton ?
									<div className={styles.buttonContainer} key={i}>
										<Breakpoints>
											{breakpoint => {
												const isDesktop = ['lg', 'xl'].includes(breakpoint);
												return (
													<Button
														className={styles.button}
														link={item.to}
														title={item.title}
														filled
														negative={!isDesktop || (isDesktop && headerActive)}
														large>
														{item.title}
													</Button>);
											}}
										</Breakpoints>
									</div> :
									<NavItem
										id={item.id}
										headerActive={headerActive}
										item={item}
										active={i === this.state.activeSubMenu}
										onToggleSubMenu={this.toggleSubMenu.bind(this, i)}
										key={i}
									/>)}
							</ul>
						)}
					</nav>
				</div>
			);
		}
	}
);

Nav.propTypes = {
	items: PropTypes.arrayOf(PropTypes.object),
	white: PropTypes.bool,
	active: PropTypes.bool,
	headerActive: PropTypes.bool,
	onActivateSubMenu: PropTypes.func,
	onDeactivateSubMenu: PropTypes.func
};

Nav.defaultProps = {
	items: [],
	white: false,
	active:false,
	headerActive: false,
	onActivateSubMenu: () => {},
	onDeactivateSubMenu: () => {}
};

export default Nav;
