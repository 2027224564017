import { cmsModule } from '@utils';

export default {
	label: 'Services',
	name: 'services',
	widget: 'object',
	fields: [
		{
			label: 'Section title',
			name: 'sectionTitle',
			required: false
		},
		cmsModule('scale-your-team'),
		cmsModule('digital-product-development')
	]
};
