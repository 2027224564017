import React from 'react';
import PropTypes from 'prop-types';
import styles from './button.module.scss';
import { classNames, getWindow } from '@utils';
import Link from '@components/link';
import Icon from '@components/icon';

class Button extends React.Component {
	constructor(props) {
		super();

		if (props.color) {
			this.colorId = `color-${props.color.replace('#', '')}`;
			this.colorClass = `button-colored--${this.colorId}`;
		}
	}

	componentDidMount() {
		this.window = getWindow();

		if (this.props.color && !this.window.document.getElementById(this.colorId)) {
			const style = `
				.${this.colorClass}:not(:hover) {
					color: ${this.props.color}
				}

				.${this.colorClass}:hover,
				.${styles.buttonNegative}.${this.colorClass}:hover {
					background-color: ${this.props.color};
				}
			`;
			this.colorStyleTag = this.window.document.createElement('style');
			this.colorStyleTag.id = this.colorId;
			this.colorStyleTag.innerHTML = style;
			this.window.document.head.appendChild(this.colorStyleTag);
		}
	}

	componentWillUnmount() {
		if (this.colorStyleTag) {
			this.window.document.head.removeChild(this.colorStyleTag);
		}
	}

	renderChildren() {
		return (
			<React.Fragment>
				<span className={styles.children}>{this.props.children}</span>
				{!!this.props.iconAfter && (
					<span
						className={classNames(styles.after, this.props.setDefaultIconColor && styles.defaultIconColor)}
					>
						<Icon name={this.props.iconAfter} />
					</span>
				)}
			</React.Fragment>
		);
	}

	render() {
		const { block, negative, primary, link, disabled, filled, rounded, small, large, color, shadow, id, dark } =
			this.props;

		const className = classNames(
			this.props.className,
			styles.button,
			color && this.colorClass,
			block && styles.buttonBlock,
			negative && styles.buttonNegative,
			primary && styles.buttonPrimary,
			filled && styles.buttonFilled,
			rounded && styles.buttonRounded,
			shadow && styles.buttonShadow,
			small && styles.buttonSmall,
			large && styles.buttonLarge,
			disabled && styles.buttonDisabled,
			dark && styles.buttonDark
		);

		const TagName = this.props.tagName;

		return (
			<React.Fragment>
				{!!link && (
					<Link
						id={id}
						to={link}
						title={this.props.title}
						className={className}
						new-tab={this.props['new-tab']}
					>
						{this.renderChildren()}
					</Link>
				)}
				{!link && (
					<TagName disabled={disabled} className={className}>
						{this.renderChildren()}
					</TagName>
				)}
			</React.Fragment>
		);
	}
}

Button.propTypes = {
	type: PropTypes.string,
	id: PropTypes.string,
	setDefaultIconColor: PropTypes.bool
};

Button.defaultProps = {
	tagName: 'button',
	setDefaultIconColor: false
};

export default Button;
