import { cmsModule } from '@utils';

const sectionDescription = cmsModule('section-description').filter(field => {
	return field.name !== 'description';
});

export default {
	label: 'We Are',
	name: 'we-are',
	widget: 'object',
	fields: [
		...sectionDescription,
		{
			label: 'sub-section',
			name: 'subSection',
			widget: 'object',
			fields: [
				{
					label: 'Title',
					name: 'title',
					required: false,
					widget: 'string'
				},
				{
					label: 'Description',
					name: 'description',
					required: false,
					widget: 'text'
				}
			]
		}
	]
};
