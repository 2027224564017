import SwiperComponent from '@components/swiper';
import React from 'react';
import items from '@static/cms/pages/portfolio.json';
import CaseCard from '../case-card';
import styles from './cases-carousel.module.scss';

const Carousel = ({ cases, title }) => {
	const carousel = ['sm', 'md', 'lg', 'xl'];
	return (
		<section className={styles.section}>
			<div className={styles.title}>{title}</div>
			<SwiperComponent
				renderItems={cases.map((item, i) => {
					return (
						<div key={i} className={styles.item}>
							<CaseCard {...item} slug={item.case} />
						</div>
					);
				})}
				carousel={carousel}
				navigationStyle={styles.navigation}
				noPaddingRight
				noPaddingLeft
			/>
		</section>
	);
};
const CasesCarousel = ({ slugs, ...props }) => {
	const cases = !!slugs ? slugs.map(slug => items.items.find(item => item.case === slug)) : props.cases;
	return <Carousel {...props} cases={cases} />;
};

CasesCarousel.propTypes = {};

export default CasesCarousel;
