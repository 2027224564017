export default [
	{
		name: 'phone',
		label: 'Phone',
		widget: 'string'
	},
	{
		name: 'address',
		label: 'Address',
		widget: 'string'
	},
	{
		name: 'city-state-zipcode',
		label: 'City, State, Zipcode',
		widget: 'string'
	}
];
