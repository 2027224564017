export default [
	{
		name: 'quote',
		label: 'Quote',
		widget: 'text'
	},
	{
		name: 'author',
		label: 'Author',
		widget: 'text'
	}
];
