import { cmsModule } from '@utils';

export default {
	label: 'Capabilities',
	name: 'capabilities',
	widget: 'object',
	fields: [
		cmsModule('intro'),
		{
			label: 'Cards',
			name: 'cards',
			widget: 'list',
			fields: [
				{
					label: 'Id',
					name: 'id',
					widget: 'string'
				},
				{
					label: 'Title',
					name: 'title',
					widget: 'string'
				},
				{
					label: 'Image',
					name: 'image',
					widget: 'image'
				},
				{
					label: 'Image Active',
					name: 'imageActive',
					widget: 'image'
				},
				{
					label: 'Description',
					name: 'description',
					widget: 'string'
				},
				{
					label: 'Link',
					name: 'link',
					widget: 'object',
					fields: cmsModule('link')
				}
			]
		}
	]
};
