export default [
	{
		label: 'Link title',
		required: false,
		name: 'title'
	},
	{
		label: 'Link to',
		required: false,
		name: 'to'
	},
	{
		label: 'Link rel',
		name: 'rel',
		required: false
	},
	{
		label: 'Open link in new tab',
		name: 'new-tab',
		required: false,
		widget: 'boolean'
	}
];
