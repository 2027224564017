import { classNames } from '@utils';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './phone.module.scss';

const Phone = ({ phone, className }) => (
    <div className={classNames(styles.phone, className)}>
        <a href={`tel:${phone.replace(/\s+/g, '')}`}>{phone}</a>
    </div>
);

Phone.propTypes = {
    phone: PropTypes.string.isRequired,
    className: PropTypes.string
};

Phone.defaultProps = {
    className: null
};

export default Phone;
