import { classNames } from '@utils';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './city.module.scss';

const City = ({ city, className }) => (
    <div className={classNames(styles.city, className)}> {city} </div>
);

City.propTypes = {
    city: PropTypes.string.isRequired,
    className: PropTypes.string
};

City.defaultProps = {
    className: null
};

export default City;
