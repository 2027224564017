export default {
	name: 'jobs',
	label: 'Jobs',
	widget: 'object',
	fields: [
		{
			name: 'title',
			label: 'Title'
		},
		{
			name: 'noOpenPositions',
			label: 'No Open Position',
			widget: 'object',
			fields: [
				{
					name: 'title',
					label: 'Title'
				},
				{
					name: 'location',
					label: 'Location',
					widget: 'object',
					fields: [
						{
							name: 'uruguay',
							label: 'Uruguay'
						},
						{
							name: 'colombia',
							label: 'Colombia'
						}
					]
				}
			]
		}
	]
};
