import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import useImg from '../../hooks/use-img';

const GatsbyImageCore = ({ sources, alt, imgClassName }) => {
	const src = useImg(sources);
	const imageSrc = getImage(src.node.childImageSharp);

	return <GatsbyImage className={imgClassName} image={imageSrc} alt={alt} loading='lazy' />;
};

GatsbyImageCore.propTypes = {
	sources: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired,
	imgClassName: PropTypes.string.isRequired
};

export default GatsbyImageCore;
