export default {
	label: 'Tools',
	name: 'tools',
	widget: 'object',
	required: false,
	fields: [
		{ name: 'title', label: 'Title', widget: 'string', required: false },
		{ name: 'showItemsName', label: 'Show items name', widget: 'boolean', required: false },
		{ name: 'isSmall', label: 'Show small icons', widget: 'boolean', required: false },
		{
			name: 'items',
			label: 'Items',
			widget: 'list',
			required: false,
			fields: [
				{ name: 'image', label: 'Image', widget: 'image', required: false },
				{ name: 'name', label: 'Name', widget: 'string', required: false }
			]
		}
	]
};
