import { externalUrl } from '@utils';
import { Link as GatsbyLink } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const Link = (props) => {
	let to = !!props.to && props.to.substr(props.to.length - 1) !== '/' ? props.to + '/' : props.to;
	const noChildren = {
		...props,
		to,
		children: null,
		target: props['new-tab'] ? '_blank' : props['target'],
		'new-tab': null
	};
	// if is external url or is not needed to be opened in new tab(is not compatible with GatsbyLink)
	return !externalUrl(to) && !props['new-tab'] ? (
		<GatsbyLink id={props.id} {...noChildren}>
			{props.children}
		</GatsbyLink>
	) : (
		<a id={props.id} href={to} {...noChildren}>
			{props.children}
		</a>
	);
};

Link.propTypes = {
	id: PropTypes.string
};

Link.defaultProps = {
	to: '/'
};

export default Link;
