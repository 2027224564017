import PropTypes from 'prop-types';
import React from 'react';

import styles from './section-title-vertical.module.scss';

const SectionTitleVertical = ({ className, title, h1Title, h2Title, h3Title }) => (
	<div className={className}>
		{h1Title && <h1 className={styles.title}>{title}</h1>}
		{h2Title && <h2 className={styles.title}>{title}</h2>}
		{h3Title && <h3 className={styles.title}>{title}</h3>}
		{!(h1Title || h2Title || h3Title) && <p className={styles.title}>{title}</p>}
	</div>
);

SectionTitleVertical.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string.isRequired,
	h1Title: PropTypes.bool,
	h2Title: PropTypes.bool,
	h3Title: PropTypes.bool
};

SectionTitleVertical.defaultProps = {
	className: null,
	h1Title: false,
	h2Title: false,
	h3Title: false
};

export default SectionTitleVertical;
