import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './tags.module.scss';
import Tag from '@components/tag';
import { classNames } from '@utils';

const Tags = ({ words, onFilter, isClickeable, className, tagClassName }) => {
	const handleSelectTag = useCallback(wordKey => {
		onFilter(wordKey);
	}, [onFilter]);

	return (
		words && (
			<div className={classNames(styles.tagsContainer, className)}>
				{words.map((w, i) => (
					<Tag
						key={i}
						word={w}
						onSelectTag={handleSelectTag}
						isClickeable={isClickeable}
						className={tagClassName}
					/>
				))}
			</div>
		)
	);
};

Tags.propTypes = {
	className: PropTypes.string,
	tagClassName: PropTypes.string,
	words: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, value: PropTypes.string }).isRequired),
	onFilter: PropTypes.func,
	isClickeable: PropTypes.bool
};

Tags.defaultProps = {
	className: null,
	tagClassName: null,
	onFilter: null,
	isClickeable: false
};

export default Tags;
