import { cmsModule } from '@utils';

export default {
	label: 'Reviews',
	name: 'reviews',
	widget: 'object',
	required: false,
	fields: [
		{
			label: 'Items',
			name: 'items',
			widget: 'list',
			fields: cmsModule('quote').map(field => {
				field.required = true;
				return field;
			})
		}
	]
};
