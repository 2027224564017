import { cmsModule } from '@utils';

export default {
	label: 'Trajectory',
	name: 'trajectory',
	widget: 'object',
	fields: [
		{
			label: 'Title',
			name: 'title',
			widget: 'text'
		},
		{
			label: 'Milestones',
			name: 'milestones',
			widget: 'list',
			fields: cmsModule('milestone').map(field => {
				field.required = true;
				return field;
			})
		}
	]
}
