import React from 'react';
import PropTypes from 'prop-types';
import { ReactMarkdown } from '@components';
import AnimatedComponent, { TranslateDirection } from '@components/animated-component';
import Button from '@components/button';
import CollapsibleContent from '@components/collapsible-content';
import Picture from '@components/picture';
import { Breakpoints, classNames } from '@utils';
import Link from '@components/link';
import styles from './section-description.module.scss';

const H2Title = ({ asLinkH2Title, linkH2To, title, className }) =>
	asLinkH2Title && linkH2To ? (
		<Link to={linkH2To} new-tab>
			<h2 className={classNames(className, styles.linkTitle)}>{title}</h2>
		</Link>
	) : (
		<h2 className={className}>{title}</h2>
	);

const SectionDescription = (props) => {
	const {
		animate,
		isMain,
		sectionTitle,
		title,
		subtitle,
		subtitleImage,
		description,
		className,
		secondChild,
		h1Title,
		h2Title,
		asLinkH2Title,
		linkH2To,
		h3Title,
		h2Subtitle,
		buttons
	} = props;
	return (
		<div className={classNames(isMain && styles.isMain, className)}>
			<AnimatedComponent animate={animate} move={TranslateDirection.Y}>
				{!!sectionTitle && <div className={styles.sectionTitle}>{sectionTitle}</div>}
				{!!title && !(h1Title || h2Title || h3Title) && <p className={styles.title}>{title}</p>}
				{!!title && h1Title && <h1 className={styles.title}>{title}</h1>}
				{!!title && h2Title && (
					<H2Title className={styles.title} asLinkH2Title={asLinkH2Title} linkH2To={linkH2To} title={title} />
				)}
				{!!title && h3Title && <h3 className={styles.title}>{title}</h3>}
				{!!subtitle && !h2Subtitle && <div className={styles.subtitle}>{subtitle}</div>}
				{!!subtitle && h2Subtitle && <h2 className={styles.subtitle}>{subtitle}</h2>}
				{subtitleImage && <Picture className={styles.picture} sources={subtitleImage} />}
				{!!description && (
					<div
						className={
							secondChild ? classNames(styles.description, styles.descriptionSecond) : styles.description
						}
					>
						<Breakpoints>
							{(breakpoint) => {
								const descriptionFormatted = description.replace(/\[\+\]/g, '');
								return ['xs', 'sm'].includes(breakpoint) ? (
									<CollapsibleContent content={description} />
								) : (
									<ReactMarkdown children={descriptionFormatted} />
								);
							}}
						</Breakpoints>
					</div>
				)}
				{buttons && !!buttons.length && (
					<div className={styles.buttonsContainer}>
						{buttons.map(({ title, ...buttonProps }) => (
							<Button key={title} className={styles.button} title={title} {...buttonProps}>
								{title}
							</Button>
						))}
					</div>
				)}
			</AnimatedComponent>
		</div>
	);
};

SectionDescription.propTypes = {
	animate: PropTypes.bool,
	isMain: PropTypes.bool,
	sectionTitle: PropTypes.string,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	subtitleImage: PropTypes.string,
	description: PropTypes.string,
	buttons: PropTypes.arrayOf(PropTypes.object),
	asLinkH2Title: PropTypes.bool,
	linkH2To: PropTypes.string
};

SectionDescription.defaultProps = {
	animate: false,
	asLinkH2Title: false,
	linkH2To: null
};

export default SectionDescription;
