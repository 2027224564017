import { cmsPreviewPane } from './cms-module';

const _window = (this || global || globalThis).window;

const getWindow = () => {
	if (!_window) {
		return _window;
	}

	// CMS preview
	const previewPane = cmsPreviewPane();
	return previewPane ? previewPane.contentWindow : _window;
};

export { getWindow };
