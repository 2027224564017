export default [
	{
		name: 'title',
		label: 'Title'
	},
	{
		name: 'subtitle',
		label: 'Subtitle'
	}
];
