export default [
	{
		label: 'Title tag',
		name: 'title-tag',
		required: false,
		widget: 'string'
	},
	{
		label: 'Title tag after',
		name: 'title-tag-after',
		hint: 'It apears on title tag after the divider.',
		required: false,
		widget: 'string'
	},
	{
		label: 'Meta description',
		name: 'meta-description',
		required: false,
		widget: 'text'
	},
	{
		label: 'Image',
		name: 'image',
		required: false,
		widget: 'image'
	}
];
