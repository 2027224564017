export default [
	{
		name: 'sectionTitle',
		label: 'Section Name',
		required: false
	},
	{
		name: 'title',
		label: 'Title',
		required: false
	},
	{
		name: 'subtitle',
		label: 'Subtitle',
		required: false,
		widget: 'text'
	},
	{
		name: 'description',
		label: 'Description',
		required: false,
		widget: 'text'
	},
	{
		label: 'Buttons',
		name: 'buttons',
		required: false,
		widget: 'list',
		fields: [
			{
				label: 'Title',
				name: 'title',
				required: true,
				widget: 'string'
			},
			{
				label: 'link',
				name: 'link',
				required: true,
				widget: 'string'
			},
			{
				label: 'Filled',
				name: 'filled',
				required: false,
				widget: 'boolean'
			}
		]
	}
];
