import React from 'react';

import Button from '@components/button';
import Link from '@components/link';
import Picture from '@components/picture';
import styles from './blog-post.module.scss';

export default function Blogpost({ image, category, title, description, info, link }) {
	const infoDate = info ? new Date(info.date) : new Date();
	const month = infoDate.toLocaleString('default', { month: 'long' });
	const day = infoDate.getDate();
	const year = infoDate.getFullYear();
	return (
		<div className={styles.outerContainer}>
			<div className={styles.container}>
				<Link to={link}>
					<Picture className={styles.image} sources={image} alt="" />
				</Link>
				<div className={styles.post}>
					<Button rounded small className={styles.category}>
						{category}
					</Button>
					{info && (
						<div className={styles.info}>
							<Picture sources={info.authorImg} imgClassName={styles.img} />
							<div>
								<h6>{info.authorName}</h6>
								<p>{`${month} ${day}, ${year}`}</p>
							</div>
						</div>
					)}

					<Link to={link} className={styles.link}>
						<h3>{title}</h3>
					</Link>
					<p>{description}</p>
					<Button className={styles.button} link={link} iconAfter="btn-arrow" rounded shadow filled>
						Read Post
					</Button>
				</div>
			</div>
		</div>
	);
}
