import React from 'react';
import { getWindow } from './window';

export const breakpoints = {
	xs: 0,
	sm: 375,
	md: 768,
	lg: 1024,
	xl: 1280
};

export class Breakpoints extends React.Component {
	constructor() {
		super();

		this.medias = {};
		this.listeners = {};

		this.state = {
			breakpoint: 'xs'
		};

		this.breakpoints = breakpoints;
	}

	componentDidMount() {
		this.window = getWindow();

		Object.entries(this.breakpoints).forEach(([name, value], index, breakpoints) => {
			let mediaQuery = `(min-width: ${value}px)`;

			if (index < breakpoints.length - 1) {
				let nextBreakpoint = breakpoints[index + 1];

				mediaQuery = `(min-width: ${value}px) and (max-width: ${nextBreakpoint[1] - 1}px)`;
			}

			this.medias[name] = this.window.matchMedia(mediaQuery);
			const listener = this.breakpointWatcher.bind(this, name);
			this.listeners[name] = listener;
			this.medias[name].addListener(listener);
			this.breakpointWatcher(name);
		});
	}

	componentWillUnmount() {
		Object.entries(this.listeners).forEach(([name, listener]) => {
			this.medias[name].removeListener(listener);
		});
	}

	breakpointWatcher(breakpoint) {
		if (this.medias[breakpoint].matches) {
			this.setState({
				breakpoint
			});
		}
	}

	render() {
		return this.props.children(this.state.breakpoint, this.breakpoints);
	}
}
