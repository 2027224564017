import { cmsModule } from '@utils';

const weAre = cmsModule('we-are');

export default [
	{
		...weAre,
		label: 'Heading',
		fields: weAre.fields.filter(field => {
			return field.name !== 'sectionTitle';
		})
	},
	cmsModule('intro'),
	{ ...cmsModule('intro'), name: 'high-performing-teams', label: 'High Performing Teams' },
	cmsModule('product-features'),
	{
		name: 'locations',
		label: 'Locations',
		widget: 'list',
		required: false,
		fields: cmsModule('location-details')
	},
	cmsModule('carousel-quote')
];
