import globalSEO from '@static/cms/settings/seo.json';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = (props) => {
	const { location, pageContext } = props;

	const isHome = location.href === `${location.origin}/`;
	const isContactPage = location.href && location.href.includes('contact');
	const pageSEO = pageContext.seo || {};
	const title = pageSEO['title-tag'] || pageContext.title;
	const titleAfter = pageSEO['title-tag-after'] || globalSEO['title-tag-after'];
	const titleTemplate = titleAfter ? `%s | ${titleAfter}` : '%s';
	const description = pageSEO['meta-description'] || globalSEO['meta-description'];
	const image = pageSEO['image'] || globalSEO['image'];
	const imageURL = location.origin + image;
	const viewport = 'width=device-width, initial-scale=1.0';
	const robots = process.env.GATSBY_SEO_INDEX_FOLLOW;

	const schemaOrgWebPage = {
		'@context': 'http://schema.org',
		'@type': 'WebPage',
		url: location.origin,
		inLanguage: {
			'@type': 'Language',
			name: 'English'
		},
		description: description,
		name: title
	};

	const itemListElement = [
		{
			'@type': 'ListItem',
			item: 'https://uruit.com',
			name: 'Homepage',
			position: 1
		}
	];

	if (!isHome) {
		itemListElement.push({
			'@type': 'ListItem',
			item: location.href,
			name: title,
			position: 2
		});
	}

	const breadcrumb = {
		'@context': 'http://schema.org',
		'@type': 'BreadcrumbList',
		description: 'Breadcrumbs list',
		name: 'Breadcrumbs',
		itemListElement
	};

	return (
		<Helmet title={title} titleTemplate={titleTemplate} defaultTitle={globalSEO['title-tag']}>
			{isHome && <script type="application/ld+json">{JSON.stringify(globalSEO['schemaOrganization'])}</script>}
			{!isHome && <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>}
			<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			<meta name="description" content={description} />
			<meta name="robots" content={robots} />
			<meta name="viewport" content={viewport} />
			<meta property="og:url" content={location.href} />
			{!isContactPage && <meta property="og:type" content="website" />}
			{isContactPage && <meta property="og:type" content="business" />}
			{title && <meta property="og:title" content={title} />}
			{description && <meta property="og:description" content={description} />}
			{image && <meta property="og:image" content={imageURL} />}
			<meta property="og:robots" content={robots} />
			<meta property="og:viewport" content={viewport} />
		</Helmet>
	);
};

SEO.propTypes = {
	location: PropTypes.object,
	pageContext: PropTypes.object
};

SEO.defaultProps = {};

export default SEO;
