import React from 'react';
import PropTypes from 'prop-types';
import Brand from '@components/brand';
import Nav from '@components/nav';
import menu from '@static/cms/settings/menu.json';
import { classNames } from '@utils';
import styles from './header.module.scss';

const Header = ({
	active,
	subNavActive,
	white,
	navActive,
	handleNavActivate,
	handleNavDeactivate,
	handleSubNavActivate,
	handleSubNavDeactivate
}) => <header
			className={classNames(
				styles.header,
				active && styles.headerActive,
				subNavActive && styles.headerSubNavActive,
				white && styles.headerWhite,
				navActive && styles.headerNavActive
			)}
		>
			<div className={styles.container}>
				<Brand className={styles.brand} />
				<Nav
					items={menu.links}
					white={white}
					headerActive={active}
					onActivate={handleNavActivate}
					onDeactivate={handleNavDeactivate}
					onActivateSubMenu={handleSubNavActivate}
					onDeactivateSubMenu={handleSubNavDeactivate}
				/>
			</div>
		</header>;

Header.propTypes = {
	white: PropTypes.bool,
	active:  PropTypes.bool,
	subNavActive:  PropTypes.bool,
	navActive: PropTypes.bool,
	handleNavActivate:PropTypes.func,
	handleNavDeactivate: PropTypes.func,
	handleSubNavActivate: PropTypes.func,
	handleSubNavDeactivate: PropTypes.func
};

Header.defaultProps = {
	white: false,
	active: false,
	subNavActive:  false,
	navActive: false,
	handleNavActivate:() => {},
	handleNavDeactivate: () => {},
	handleSubNavActivate: () => {},
	handleSubNavDeactivate: () => {}
};

export default Header;
