import { cmsModule } from '@utils';

export default [
	cmsModule('we-are'),
	{ ...cmsModule('l-a-headquarters'), name: 'headquarters' },
	{ ...cmsModule('l-a-san-diego'), name: 'san-diego' },
	{ ...cmsModule('cases-portfolio-view'), name: 'our-work' },
	cmsModule('carousel-quote'),
	cmsModule('capabilities'),
	cmsModule('clients')
];
