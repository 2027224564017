import camelCase from 'lodash/camelCase';

export const Capabilities = {
	UX_UI: 'UX/UI',
	MACHINE_LEARNING: 'Machine Learning',
	WEB: 'Web Development',
	MOBILE: 'Mobile',
	PRODUCT_DISCOVERY: 'Product Discovery'
};

export const Industries = {
	HEALTHCARE: 'Healthcare',
	LEARNING_EDUCATION: 'Learning & Education',
	FINTECH: 'Fintech',
	CRE: 'CRE',
	OTHERS: 'Others'
};

export const ItemTypes = {
	CASE: 'case',
	QUOTE: 'quote'
};

export const getBackgroundImageClass = capabilities => camelCase(capabilities[0]);
export const getBackgroundColorClass = industries => camelCase(industries[0]);
export const getTagClass = industries => camelCase(`tag-${industries[0]}`);
export const getTags = (capabilities, industries) => [
	...capabilities.map(c => ({ key: c, value: Capabilities[c] })),
	...industries.map(i => ({ key: i, value: Industries[i] }))
];

export function calculateScore(parentKeywords, keywords) {
	let score = 0;
	const baseScore = 100;
	parentKeywords.forEach((pk, pkIx) => {
		keywords.forEach((k, kIx) => {
			if (pk === k) {
				score += baseScore / (pkIx + 1 + (kIx + 1));
			}
		});
	});
	return score;
}

export function getRelatedCases(items, mainCapabilities, mainIndustries, slug, amount) {
	const processedCases = items.items
		.filter(item => item.type === 'case')
		.map(item =>
			item.case !== slug
				? {
						...item,
						score:
							calculateScore(mainCapabilities, item.capabilities) +
							calculateScore(mainIndustries, item.industries)
				  } : { ...item, score: -1 }
		);
	return processedCases.sort((a, b) => b.score - a.score).splice(0, amount);
}

export const NUMBER_OF_CASES = 6;
