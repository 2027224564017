export default [
	{
		name: 'title',
		label: 'Title',
		required: false,
		widget: 'string'
	},
	{
		name: 'image',
		label: 'Image',
		required: false,
		widget: 'image'
	},
	{
		name: 'column1',
		label: 'Column1',
		required: false,
		widget: 'list',
		fields: [
			{
				name: 'description',
				label: 'Description'
			}
		]
	},
	{
		name: 'column2',
		label: 'Column2',
		required: false,
		widget: 'list',
		fields: [
			{
				name: 'description',
				label: 'Description'
			}
		]
	},
	{
		name: 'reverse',
		label: 'Reverse',
		required: false,
		widget: 'boolean'
	},
	{
		name: 'theme',
		label: 'Theme',
		required: false,
		widget: 'select',
		options: ['blue', 'yellow', 'red'],
		default: 'blue'
	}
];
