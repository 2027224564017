export default {
	label: 'Title Checklist',
	name: 'title-checklist',
	widget: 'object',
	fields: [
		{
			label: 'Title',
			name: 'title',
			required: true,
			widget: 'string'
		},
		{
			label: 'Description',
			name: 'description',
			widget: 'string'
		},
		{
			label: 'Button',
			name: 'button',
			widget: 'object',
			fields: [
				{
					name: 'title',
					widget: 'string'
				},
				{
					name: 'link',
					widget: 'string'
				}
			]
		},
		{
			label: 'List',
			name: 'list',
			widget: 'list',
			required: true,
			fields: [
				{
					label: 'Text',
					name: 'text',
					widget: 'string',
					required: true
				}
			]
		}
	]
};
