import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const useImg = sources => {
	const data = useStaticQuery(graphql`
		query {
			allFile {
				edges {
					node {
						relativePath
						childImageSharp {
							gatsbyImageData(layout: CONSTRAINED)
						}
					}
				}
			}
		}
	`);
	return useMemo(
		() =>
			data.allFile.edges.find(n => {
				return n.node.relativePath.includes(sources.replace('/cms/assets/', ''));
			}),
		[data, sources]
	);
};
export default useImg;
