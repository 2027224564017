import React from 'react';
import PropTypes from 'prop-types';
import styles from './layout.module.scss';
import Footer from '@components/footer';
import Header from '@components/header';
import SEO from '@components/seo/SEO';
import CookiesBanner from '@components/cookies-banner';
import { getWindow, classNames, scrollingElement } from '@utils';

class Layout extends React.Component {
	constructor(props) {
		super();

		this.state = {
			headerWhite: props.headerWhite,
			headerActive: false,
			navActive: false
		};

		this.handleScroll = this.handleScroll.bind(this);
		this.handleNavActivate = this.handleNavActivate.bind(this);
		this.handleNavDeactivate = this.handleNavDeactivate.bind(this);
		this.handleSubNavActivate = this.handleSubNavActivate.bind(this);
		this.handleSubNavDeactivate = this.handleSubNavDeactivate.bind(this);
	}

	componentDidMount() {
		this.window = getWindow();
		this.scrollingElement = scrollingElement();

		this.body = this.window.document.body;
		this.window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount() {
		this.window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll() {
		if (this.state.headerDisabled) {
			return;
		}

		const { scrollTop } = this.scrollingElement;
		const { headerActive, navActive } = this.state;

		this.scrollTop = scrollTop;

		if (navActive) {
			return;
		}

		if (scrollTop > 0 && !headerActive) {
			this.activateHeader();
		} else if (scrollTop === 0 && headerActive) {
			this.deactivateHeader();
		}
	}

	handleNavActivate() {
		this.setState({
			navActive: true,
			headerActive: this.state.headerDisabled ? this.state.headerActive : this.scrollTop === 0
		});
	}

	handleNavDeactivate() {
		this.setState({
			navActive: false,
			headerActive: this.state.headerDisabled ? this.state.headerActive : this.scrollTop > 0
		});
	}

	handleSubNavActivate() {
		this.setState({
			subNavActive: true
		});
	}

	handleSubNavDeactivate() {
		this.setState({
			subNavActive: false
		});
	}

	activateHeader() {
		this.setState({
			headerActive: true
		});
	}

	deactivateHeader() {
		this.setState({
			headerActive: false
		});
	}

	activateWhiteHeader() {
		this.setState({
			headerWhite: true
		});
	}

	deactivateWhiteHeader() {
		this.setState({
			headerWhite: false
		});
	}

	disableHeader() {
		this.setState({
			headerDisabled: true
		});
	}

	enableHeader() {
		this.setState({
			headerDisabled: false
		});
	}

	render() {
		const { hideLocations, location, pageContext, padded, theme, noMarginTop } = this.props;
		return (
			<React.Fragment>
				<SEO location={location} pageContext={pageContext} />
				<Header
					active={this.state.headerActive}
					white={this.state.headerWhite}
					navActive={this.state.navActive}
					subNavActive={this.state.subNavActive}
					handleNavActivate={this.handleNavActivate}
					handleNavDeactivate={this.handleNavDeactivate}
					handleSubNavActivate={this.handleSubNavActivate}
					handleSubNavDeactivate={this.handleSubNavDeactivate}
				/>
				<main
					className={classNames(styles.main, padded && styles.headerPad, noMarginTop && styles.noMarginTop)}
				>
					{this.props.children}
				</main>
				<Footer hideLocations={hideLocations} theme={theme} />
				<CookiesBanner />
			</React.Fragment>
		);
	}
}

Layout.propTypes = {
	children: PropTypes.node,
	hideLocations: PropTypes.bool,
	location: PropTypes.object,
	pageContext: PropTypes.object,
	padded: PropTypes.bool,
	noMarginTop: PropTypes.bool
};

Layout.defaultProps = {
	hideLocations: false,
	padded: false,
	noMarginTop: false
};

export default Layout;
