import Button from '@components/button';
import Picture from '@components/picture';
import Tags from '@components/tags';
import { classNames, getBackgroundColorClass, getBackgroundImageClass, getTagClass, getTags } from '@utils';
import React, { useMemo } from 'react';
import { ReactMarkdown } from '@components';
import styles from './case-card.module.scss';

const CaseCard = (props) => {
	const { capabilities, industries, title, description, image, slug } = props;
	const backgroundImageClass = useMemo(() => getBackgroundImageClass(capabilities), [capabilities]);
	const backgroundColorClass = useMemo(() => getBackgroundColorClass(industries), [industries]);
	const tagClass = useMemo(() => getTagClass(industries), [industries]);
	const tags = useMemo(() => getTags(capabilities, industries), [capabilities, industries]);

	return (
		<div className={styles.container}>
			<div className={classNames(styles.item, styles[backgroundImageClass], styles[backgroundColorClass])}>
				{tags && <Tags className={styles.tags} words={tags} tagClassName={styles[tagClass]} />}
				<div className={styles.content}>
					<h2 className={styles.text}>
						<ReactMarkdown className={styles.title} children={title} />
						<ReactMarkdown className={styles.description} children={description} />
					</h2>
					{image && <Picture className={styles.image} sources={image} />}
				</div>
				<div className={styles.buttonContainer}>
					<Button
						className={styles.button}
						link={`/${slug}`}
						setDefaultIconColor
						iconAfter="btn-arrow"
						block
						rounded
						shadow
						primary
						filled
					/>
				</div>
			</div>
		</div>
	);
};

export default CaseCard;
