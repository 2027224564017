import Link from '@components/link';
import React from 'react';
import styles from './legal.module.scss';

const Legal = () => (
	<div className={styles.legal}>
		<div className={styles.copyright}>&copy; {new Date().getFullYear()} Uruit By Nearsure. All rights reserved</div>
		<Link to="/privacy-policy" new-tab className={styles.link}>
			Privacy policy
		</Link>
	</div>
);

export default Legal;
