import React from 'react';
import PropTypes from 'prop-types';
import * as RM from 'react-markdown';

const ReactMarkdown = ({ children, className, newTab }) => (
	<RM linkTarget={newTab ? '_blank' : undefined} children={children} className={className} />
);

ReactMarkdown.propTypes = {
	className: PropTypes.string,
	newTab: PropTypes.bool,
	children: PropTypes.node.isRequired
};

ReactMarkdown.defaultProps = {
	className: '',
	newTab: false
};

export default ReactMarkdown;
