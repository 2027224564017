const bullet = {
	name: 'bullet',
	label: 'Bullet',
	widget: 'select',
	required: false,
	options: ['circle', 'check']
};

const title = {
	name: 'title',
	label: 'Title',
	widget: 'markdown'
};

export default [
	bullet,
	{
		name: 'items',
		label: 'Items',
		widget: 'list',
		required: false,
		fields: [
			title,
			{
				name: 'sublist',
				label: 'Sublist',
				widget: 'object',
				fields: [
					bullet,
					{
						name: 'items',
						label: 'Items',
						widget: 'list',
						required: false,
						fields: [title]
					}
				]
			}
		]
	},
	{
		name: 'link',
		label: 'Link',
		widget: 'markdown',
		required: false
	}
];
