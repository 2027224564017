export default {
	label: 'Clients',
	name: 'clients',
	widget: 'object',
	required: false,
	fields: [
		{
			label: 'Title',
			name: 'title',
			widget: 'string'
		},
		{
			label: 'Items',
			name: 'items',
			widget: 'list',
			fields: [
				{
					name: 'image',
					label: 'Image',
					required: false,
					widget: 'image'
				},
				{
					name: 'name',
					label: 'Name',
					required: false,
					widget: 'text'
				}
			]
		}
	]
};
